import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { isMobile } from "react-device-detect";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/headers/Footer";
import MenuLists from "./components/headers/MenuLists";
import MainRoutes from "./Routes";
import MobileMenuList from "./components/headers/MobileMenuList";
import { ProviderDataProvider } from "./context/ProviderDataContext";
import { useEffect } from "react";

function App() {
  // const { pathname } = window.location;

  useEffect(() => {
    // Function to handle the context menu event
    const handleContextMenu = (event) => {
      // Allow right-click if the hostname is 'localhost'
      if (window.location.hostname === "localhost") {
        return;
      }

      event.preventDefault(); // Prevent the context menu for other hostnames
    };

    // Function to handle keyboard shortcuts
    const handleKeyDown = (event) => {
      // Prevent common developer tools shortcuts (F12, Ctrl+Shift+I, etc.)
      if (
        event.key === "F12" || // F12 key
        (event.ctrlKey && event.shiftKey && event.key === "I") || // Ctrl+Shift+I
        (event.metaKey && event.altKey && event.key === "I") // Cmd+Option+I (macOS)
      ) {
        event.preventDefault();
      }
    };

    // Add event listeners when the component is mounted
    window.addEventListener("contextmenu", handleContextMenu);
    window.addEventListener("keydown", handleKeyDown);

    // Remove event listeners when the component is unmounted
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const isAllowedDomain = window.location.href.startsWith(
    "https://provider.merago.com/"
  );

  return (
    <>
      <ProviderDataProvider>
        <ToastContainer autoClose={3000} theme={"colored"} />
        {!isMobile ? <MenuLists /> : <MobileMenuList />}
        {isAllowedDomain && (
          <TawkMessengerReact
            propertyId="629dc2fcb0d10b6f3e75e4ff"
            widgetId="1g4s43bk9"
          />
        )}
        <MainRoutes />
        <Footer />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </ProviderDataProvider>
    </>
  );
}

export default App;
