import React from "react";
import { Link, useLocation } from "react-router-dom";
import navigationItems from "../../../utils/constants/NavigationItems";

const AgentLoginMenu = () => {
  const location = useLocation();
  const user = localStorage.getItem("agentID");

  return (
    <>
      {!user && (
        <div className="navbar__login__screen">
          <div className="navbar__logo">
            <img
              src="https://static.meragoapp.com/images/menuLogo.png"
              alt="logo"
              width="55px"
              height="55px"
            />
          </div>
          {!location?.pathname?.includes("guest") && (
            <div className="navbar__single">
              {navigationItems.agentLoginMenu.map((items) => (
                <Link
                  key={items.text}
                  to={items.to}
                  className={
                    location.pathname.includes(items.to) ? "navbar_active" : ""
                  }
                >
                  {items.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AgentLoginMenu;
