import axios from "axios";
const getAuthToken = () => {
  const auth = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;
  if (auth) {
    return `Bearer ${auth}`;
  }
  return null;
};

const logout = axios.create({
  baseURL: process.env.REACT_APP_LOGINURL,
  headers: {
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
    "X-Frame-Options": "SAMEORIGIN",
    "Content-Security-Policy": "default-src 'self'",
    "X-Content-Type-Options": "nosniff",
    "Referrer-Policy": "strict-origin-when-cross-origin",
  },
});
logout.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: getAuthToken(),
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (url, params) =>
    logout
      .get(url, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      }),
};
//* Get Peer Message
export const getLogout = () => request.get("/logout");
