const getDropDownOptions = (type, list = []) => {
  let options = [];

  switch (type) {
    case "clinicianList": {
      options = list.map((item) => {
        const object = { ...item };
        object.label = `${item?.lastName}, ${item?.firstName}`;
        object.value = item.id;
        return object;
      });
      break;
    }

    case "languageList": {
      options = list.map((item) => {
        const object = { ...item };
        object.label = item?.language;
        object.value = item?.id;
        return object;
      });
      break;
    }
    case "specList": {
      options = list.map((item) => {
        const object = { ...item };
        object.label = item?.specialisation;
        object.value = item?.id;
        return object;
      });
      break;
    }

    case "countryList": {
      options = list.map((item) => {
        const object = { ...item };
        object.label = item?.countryName;
        object.value = item?.countryCode;
        return object;
      });
      break;
    }

    case "doctorsList": {
      options = list.map((item) => {
        const object = { ...item };
        object.label = `${item?.name}(${item?.specialization})`;
        object.value = item?.id;
        return object;
      });
      break;
    }
    case "stateList": {
      options = list.map((item) => {
        const object = { ...item };
        object.label = item?.stateName;
        object.value = item?.id;
        return object;
      });
      break;
    }
    case "cityList": {
      options = list.map((item) => {
        const object = { ...item };
        object.label = item?.cityName;
        object.value = item?.id;
        return object;
      });
      break;
    }

    case "meragoHublist": {
      options = list.map((item) => {
        const object = { item };
        object.label = item?.name;
        object.value = item?.id;
        return object;
      });
      break;
    }
    case "allHublist": {
      options = list
        ?.map((item) => {
          if (item?.hubKey !== "Merago_SAF") {
            return {
              label: item?.name,
              value: item?.id,
            };
          }
          return null; // Skip items that don't meet the condition
        })
        .filter(Boolean); // Remove null values from the array
      break;
    }

    case "meragoHublistFilter": {
      // eslint-disable-next-line
      options = list.map((item) => {
        if (item?.subscribed === true) {
          const object = { item };
          object.label = item?.name;
          object.value = item?.name;
          return object;
        }
      });
      break;
    }
    case "meragoHublistCODFilter": {
      // eslint-disable-next-line
      options = list.map((item) => {
        if (item?.subscribed === true && item?.name !== "Skincare") {
          const object = { item };
          object.label = item?.name;
          object.value = item?.name;
          return object;
        }
      });
      break;
    }

    case "meragoHublistUnsubscribed": {
      // eslint-disable-next-line
      options = list.map((item) => {
        if (item?.subscribed === false) {
          const object = { item };
          object.label = item?.name;
          object.value = item?.name;
          return object;
        }
      });
      break;
    }

    case "salutationList": {
      options = list.map((item) => {
        const object = { ...item };
        object.label = item?.salutation;
        object.value = item?.id;
        return object;
      });
      break;
    }
    case "availableModes": {
      options = list.map((item) => {
        const object = { item };
        object.label = item?.consultationMode?.split("_").join("-");
        object.value = item?.consultationMode;
        return object;
      });
      break;
    }

    case "stateMedicalCouncil": {
      options = list.map((item) => {
        const object = { item };
        object.label = item?.medicalCouncil;
        object.value = item?.id;
        return object;
      });
      break;
    }
    case "availableTimeSlot": {
      options = list.map((item) => {
        const object = { item };
        object.label = item?.availability;
        object.value = item?.id;
        return object;
      });
      break;
    }

    case "clinincDetailsDropdown": {
      options = list.map((item) => {
        const object = { item };
        object.label = item?.clinicName;
        object.value = item?.id;
        return object;
      });
      break;
    }
    case "specialisationlistDropdown": {
      options = list.map((item) => {
        const object = { item };
        object.label = item?.specialisation;
        object.value = item?.id;
        return object;
      });
      break;
    }
    case "qualificationslistDropdown": {
      options = list.map((item) => {
        const object = { item };
        object.label = item?.qualification;
        object.value = item?.id;
        return object;
      });
      break;
    }
    default: {
      return options;
    }
  }

  return options;
};
export default getDropDownOptions;
