import axios from "axios";
const login = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
    "X-Frame-Options": "SAMEORIGIN",
    "Content-Security-Policy": "default-src 'self'",
    "X-Content-Type-Options": "nosniff",
    "Referrer-Policy": "strict-origin-when-cross-origin",
  },
});

login.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (url, params) =>
    login
      .get(url, { params })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response.data)),
  post: (url, data, params) =>
    login
      .post(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      }),
  put: (url, data) =>
    login
      .put(url, data)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response.data)),
  patch: (url, data) =>
    login
      .patch(url, data)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response.data)),
  delete: (url, data) =>
    login
      .delete(url, { data: data })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response.data)),
};

export const forgotPassword = (params) =>
  request.post("/merago/api/user/provider/forgotPassword", null, params);

export const verifyOTP = (data) =>
  request.post("/merago/api/user/provider/verifyOTP", data);

export const resetPassword = (data) =>
  request.post("/merago/api/user/resetPassword", data);

export const registerProvider = (data) =>
  request.post("/merago/api/salon/register/provider", data);

export const getCountry = () =>
  request.get("/merago/api/user/nonsecure/getCountries");

export const getCity = (params) =>
  request.get("/merago/api/user/nonsecure/getCitiesByState", params);

export const sendOtpRegistration = (params) =>
  request.post("/merago/api/salon/register/sendOtp", null, params);

export const verifyOTPRegistration = (params) => {
  request.post("/merago/api/salon/register/verifyOtp", null, params);
};
export const sendLoginOTP = (data) =>
  request.post("/merago/api/user/verifyUsernameAndSendOTP", data);
export const doctorVerifyOTP = (data) =>
  request.post("/merago/api/user/doctorVerifyOTP", data);
