import { useMutation, useQuery, useQueryClient } from "react-query";
import * as provider from "../services/providerAPI";
import * as byodLogin from "../services/byodLoginAPI";
import getDropDownOptions from "../utils/constants/SelectVariables";
const _ = require("lodash");

export const useGetProviderDetails = (params) => {
  return useQuery(
    ["Provider-Details", params],
    () => provider.getProviderDetails({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      cacheTime: 0,
      config: {
        cacheTime: 0,
      },
    }
  );
};
export const useGetProviderBankDetails = (params) => {
  return useQuery(
    ["Provider-Bank", params],
    () => provider.getProviderBankDetails(params),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};
export const useSaveBankDetails = () => {
  return useMutation((params) => provider.saveBankProvider(params), {
    retry: false,
  });
};
export const useChangePassword = () => {
  return useMutation((params) => provider.changePassword(params), {
    retry: false,
  });
};

export const useGetProfileStatus = (params) => {
  return useQuery(
    ["Profile-status", params],
    () => provider.getProfileCompletion({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      // cacheTime: 0,
      // config: {
      //   cacheTime: 0,
      // },
    }
  );
};
export const useGetSalutation = () => {
  return useQuery(["Get-Salutation"], () => provider.getSalutation(), {
    retry: false,
    select: (data) => {
      return {
        ...data,
        salutations: getDropDownOptions(
          "salutationList",
          data.salutations || []
        ),
      };
    },
  });
};

export const useGetLanguages = () => {
  return useQuery(["Get-Language"], () => provider.getLanguages(), {
    retry: false,
    select: (data) => {
      return {
        ...data,
        metaDataLanguagesList: getDropDownOptions(
          "languageList",
          data.metaDataLanguagesList || []
        ),
      };
    },
  });
};

export const useGetCountry = () => {
  return useQuery(["Get-Country"], () => provider.getCountry(), {
    retry: false,
    select: (data) => {
      return {
        ...data,
        metaDataCountries: getDropDownOptions(
          "countryList",
          data.metaDataCountries || []
        ),
      };
    },
  });
};

export const useGetStates = (params) => {
  return useQuery(
    ["Get-States", params],
    () => provider.getStates({ countryCode: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      select: (data) => {
        return {
          ...data,
          metaDataState: getDropDownOptions(
            "stateList",
            data.metaDataState || []
          ),
        };
      },
    }
  );
};

export const useGetCities = (params) => {
  return useQuery(
    ["Get-Cities", params],
    () => provider.getCities({ stateId: params }),
    {
      enabled: !_.isNil(params),
      select: (data) => {
        return {
          ...data,
          metaDataCities: getDropDownOptions(
            "cityList",
            data.metaDataCities || []
          ),
        };
      },
    }
  );
};

export const useGetDoctorPersonalInfo = (params) => {
  return useQuery(
    ["Doctor-PersonalInfo", params],
    () => provider.getDoctorPersonalInfo({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

export const useUpdateDoctorPersonalProfile = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => provider.updateDoctorPersonalProfile(params), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Profile-status");
    },
  });
};

export const useGetDPUrl = (params) => {
  return useQuery(
    ["DP-URL", params],
    () => provider.getDPUrl({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      cacheTime: 0,
      config: {
        cacheTime: 0,
      },
    }
  );
};

export const useRemoveDPUrl = () => {
  return useMutation((params) => provider.removeDPUrl(params), {
    retry: false,
  });
};
export const useUploadDPUrl = () => {
  return useMutation((params) => provider.uploadDPUrl(params), {
    retry: false,
  });
};

export const useGetSignatureURL = (params) => {
  return useQuery(
    ["Sign-URL", params],
    () => provider.getSignURL({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      cacheTime: 0,
      config: {
        cacheTime: 0,
      },
    }
  );
};
export const useRemoveSignUrl = () => {
  return useMutation((params) => provider.removeSignUrl(params), {
    retry: false,
  });
};
export const useUploadSignUrl = () => {
  return useMutation((params) => provider.uploadSignUrl(params), {
    retry: false,
  });
};

export const useGetProviderRoaster = (params) => {
  return useQuery(
    ["Provider-Roaster", params],
    () => provider.getProviderRoster({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

export const useGetSubscribedHubs = (params) => {
  return useQuery(
    ["Provider-Hubs", params],
    () => provider.getSubscribedHubs({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      select: (data) => {
        return {
          ...data,
          meragoHubList: getDropDownOptions(
            "meragoHublistFilter",
            data.meragoHubList || []
          ),
          meragoUnsubscribedHubList: getDropDownOptions(
            "meragoHublistUnsubscribed",
            data.meragoHubList || []
          ),
        };
      },
    }
  );
};

export const useGetOnlySubscribedHubsFilter = (params) => {
  return useQuery(
    ["Provider-Hubs-Filters", params],
    () => provider.getSubscribedHubs({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      select: (data) => {
        return {
          ...data,
          meragoHubList: getDropDownOptions(
            "meragoHublistCODFilter",
            data.meragoHubList || []
          ),
        };
      },
    }
  );
};

export const useGetProviderPayments = (params) => {
  return useQuery(
    ["Provider-Payments", params],
    () => provider.getProviderPayments(params),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};
//* Get Clinic Details
export const useGetClinicDetails = (params) => {
  return useQuery(
    ["Provider-Clinics", params],
    () => provider.getClinicDetails({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};
//* Get Patients List
export const useGetPatientsList = (params) => {
  return useQuery(
    ["Patients-List", params],
    () => provider.getPatientsList(params),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};
//* Get Member Details by UserId
export const useGetMemberDetails = (params) => {
  return useQuery(
    ["Member-Details", params],
    () => provider.getMemberDetails({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//* Get Patients & Provider List
export const useGetMemberProviderDetails = (params) => {
  return useQuery(
    ["Member-Provider", params],
    () => provider.getMemberProviderDetails(params),
    {
      enabled: !_.isEmpty(params),
      retry: true,
    }
  );
};
//* Get Provider Service Info
export const useGetProviderServiceInfo = (params) => {
  return useQuery(
    ["Service-Info", params],
    () => provider.getProviderService({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: true,
    }
  );
};
//* Get Provider Service Fee Config -Normal
export const useGetNormalServiceFeeConfig = (params) => {
  return useQuery(
    ["Service-Fees", params],
    () => provider.getProviderNormalServiceFeeConfig({ type: params }),
    {
      enabled: !_.isEmpty(params),
      retry: true,
    }
  );
};

//* Get Provider Service Fee Config - UC
export const useGetUCServiceFeeConfig = (params) => {
  return useQuery(
    ["Service-Fees", params],
    () => provider.getProviderUCServiceFeeConfig({ type: params }),
    {
      enabled: !_.isEmpty(params),
      retry: true,
    }
  );
};
//* Get Service Fee Info
export const useGetServiceFeeInfo = (params) => {
  return useQuery(
    ["Service-Fees-Info", params],
    () => provider.getServiceFeeInfo({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: true,
    }
  );
};

//* BYOD Login
export const useByodLogin = (params) => {
  return useQuery(
    ["Byod-Login", params],
    () => byodLogin.byodLoginCall(params),
    {
      enabled: !!params?.username,
      retry: false,
    }
  );
};
//* Get State Medical Council
export const useGetStateMedicalCoucil = () => {
  return useQuery(
    ["State-MedicalCouncil"],
    () => provider.getStateMedicalCouncil(),
    {
      retry: false,
      select: (data) => {
        return {
          ...data,
          medicalCouncils: getDropDownOptions(
            "stateMedicalCouncil",
            data.medicalCouncils || []
          ),
        };
      },
    }
  );
};

export const useSaveMRNDetails = () => {
  return useMutation((params) => provider.mrnLicenseSave(params), {
    retry: false,
  });
};

//* Get Service Fee Info
export const useGetClinicsDetails = (params) => {
  return useQuery(
    ["Service-Clinic-Info", params],
    () => provider.getServiceFeeInfo({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: true,
      select: (data) => {
        return {
          ...data,
          clinicAddressList: getDropDownOptions(
            "clinincDetailsDropdown",
            data.clinicAddressList || []
          ),
        };
      },
    }
  );
};

//* Get Doctor Professional Info
export const useGetDoctoProfessionalInfo = (params) => {
  return useQuery(
    ["Povider-ProfessionalInfo", params],
    () => provider.getDoctorProfessionalInformation({ providerId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

export const useUpdateDoctorProfessionalProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params) => provider.updateDoctorProfessionalProfile(params),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries("Profile-status");
      },
    }
  );
};

//* Get Specialisation
export const useGetspecialisationlist = (params) => {
  return useQuery(
    ["Specialization-List", params],
    () => provider.getspecialisationlist(),
    {
      retry: true,
      select: (data) => {
        return {
          ...data,
          doctorSpecialisationList: getDropDownOptions(
            "specialisationlistDropdown",
            data.doctorSpecialisationList || []
          ),
        };
      },
    }
  );
};
//* Get Specialisation
export const useGetQualificatiosnList = (params) => {
  return useQuery(
    ["Qualifications-List", params],
    () => provider.getQualificationslist({ countryCode: params }),
    {
      enabled: !_.isEmpty(params),
      retry: true,
      select: (data) => {
        return {
          ...data,
          metaDataQualifications: getDropDownOptions(
            "qualificationslistDropdown",
            data.metaDataQualifications || []
          ),
        };
      },
    }
  );
};
//? Update Provider Service & Fee Info
export const useUpdateProviderServiceFee = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => provider.saveServiceFeeInfo(params), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Profile-status");
      queryClient.invalidateQueries("Provider-Details");
    },
  });
};

//?Send Selected Clinics
export const useSendSelectedClinics = () => {
  return useMutation((params) => provider.getClinicProviderStatus(params), {
    retry: false,
  });
};
//?Update Provider Location
export const useUpdateProviderLocation = () => {
  return useMutation((params) => provider.updateProviderLocation(params), {
    retry: false,
  });
};
//?Provider Blocks Calendar
export const useBlockCalendar = () => {
  return useMutation((data) => provider.blockCalendar(data), {
    retry: false,
  });
};
//?Provider Unblocks Calendar
export const useUnblockCalendar = () => {
  return useMutation((data) => provider.unBlockCalendar(data), {
    retry: false,
  });
};

//?Provider Send OTP on Verification Calendar
export const useSendOTPForVerification = () => {
  return useMutation((data) => provider.sendOtpToProviderOnLogin(data), {
    retry: false,
  });
};
//* Get Geolocation - Address
export const useGetGeoLocation = (params) => {
  return useQuery(
    ["Geo-Location", params],
    () => provider.getGoogleMapsByAddess({ address: params }),
    {
      enabled: !_.isEmpty(params),
      retry: true,
    }
  );
};
//* Get Geolocation - Lat n Lng
export const useGetGeoLocationLatLng = (params) => {
  return useQuery(
    ["Geo-LatLng", params],
    () => provider.getGoogleGeoCodeLatLng({ latlng: params }),
    {
      enabled: !_.isEmpty(params),
      retry: true,
    }
  );
};
