import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { useVerifyOTP } from "../../hooks/useLogin";
import { useSendOTPForVerification } from "../../hooks/useProvider";
import LoadingButton from "../../utils/buttons/LoadingButton";
import { alertNewModal } from "../../utils/modalStyles/ModalMainStyle";
import "./OTPVerificationModal.scss";

const OTPVerificationContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [otp, setOTP] = useState();
  const {
    mutate: sendOTP,
    data: OTPData,
    isLoading: requestingOTP,
  } = useSendOTPForVerification();
  const {
    mutate: verifyOTP,
    data: verifiedOTPData,
    isLoading: verifiyingOTP,
  } = useVerifyOTP();

  const handleSendOTP = () => {
    const data = {};
    data.userId = localStorage.getItem("userID");
    sendOTP(data);
  };

  const handleVerifyOTP = () => {
    const bodyFormData = new FormData();
    bodyFormData.append("OTP", otp);
    bodyFormData.append("OTPId", OTPData?.responseString);
    verifyOTP(bodyFormData);
  };
  const handleOTP = (otp) => {
    setOTP(otp);
  };
  useEffect(() => {
    if (verifiedOTPData?.status === "SUCCESS") {
      toast.success("OTP have been verified successfully");
      setIsModalOpen(false);
    } else if (verifiedOTPData?.status === "ERROR") {
      toast.warn(verifiedOTPData?.responseString);
    }
  }, [verifiedOTPData]);
  return (
    <Modal isOpen={isModalOpen} style={alertNewModal} ariaHideApp={false}>
      <div className="html__title__wrapper">
        <div className="html__title">Verification Pending</div>
      </div>
      <div className="otp__body">
        <p className="otp__text">
          Please verify your registered mobile number to continue
        </p>
        {OTPData && (
          <OtpInput
            value={otp}
            onChange={handleOTP}
            numInputs={4}
            separator={<span>-</span>}
            isInputNum={true}
            inputStyle={{
              width: "0.65em",
              padding: "15px",
              margin: "7px",
              height: "0.7em",
            }}
            // isDisabled={!otpData ? true : false}
          />
        )}
        <LoadingButton
          isLoading={!OTPData ? requestingOTP : verifiyingOTP}
          onClick={!OTPData ? handleSendOTP : handleVerifyOTP}
          buttonText={!OTPData ? "Send OTP" : "Verify OTP"}
          className={"common__btn submit"}
          disabled={OTPData && otp?.length !== 4}
        />
        {OTPData && (
          <p>OTP has been sent to your registered mobile number and e-mail</p>
        )}
      </div>
    </Modal>
  );
};

export default OTPVerificationContainer;
